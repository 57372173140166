export default {
    path: '/',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
        // requiresAuth: true
    },
    redirect: () => {
      // the function receives the target route as the argument
      // a relative location doesn't start with `/`
      // or { path: 'profile'}
      return 'welcome'
    },
    components: {
        default: () => import(/* webpackChunkName: "AppLayout" */ '../components/Layout/AppLayout.vue'),
        // default: () => import(/* webpackChunkName: "Home" */ '../pages/Welcome.vue'),
        // default: () => import(/* webpackChunkName: "AppLayout" */ '../components/Layout/AppLayout.vue'),
    },
    children: [
        {
            path: '/welcome',
            meta: {
                requiresAuth: false,
                pwaCheck: true
            },
            component: () => import(/* webpackChunkName: "Service" */ '../pages/Welcome.vue')
        },
        {
            path: '/guest',
            meta: {
                requiresAuth: false,
                mode: 'guest'
            },
            components: {
                default: () => import(/* webpackChunkName: "Home" */ '../pages/Guest.vue'),
                Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue'),
                // NotificationBar: () => import(/* webpackChunkName: "NotificationBar" */ '../components/Layout/NotificationBar.vue'),
            }
        }, 
        {
            path: '/guest/articles/:id',
            meta: {
                requiresAuth: false,
                mode: 'guest'
            },
            components: {
                default: () => import(/* webpackChunkName: "Article" */ '../pages/Article.vue')
            }
        },
        {
            path: '/home',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "Home" */ '../pages/Home.vue'),
                Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue'),
                NotificationBar: () => import(/* webpackChunkName: "NotificationBar" */ '../components/Layout/NotificationBar.vue'),
            }
        }, {
            path: '/service',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "Service" */ '../pages/Service.vue'),
                Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue'),
                NotificationBar: () => import(/* webpackChunkName: "NotificationBar" */ '../components/Layout/NotificationBar.vue'),
            }
        }, {
            path: '/service/on-site-activities',
            meta: {
                requiresAuth: true,
                publisher: 'municipal'
            },
            components: {
                default: () => import(/* webpackChunkName: "ActiveQuestions" */ '../pages/ActiveQuestions.vue'),
                Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
            }
        }, {
            path: '/service/questions',
            meta: {
                requiresAuth: true,
                publisher: 'municipal'
            },
            components: {
                default: () => import(/* webpackChunkName: "ActiveQuestions" */ '../pages/ActiveQuestions.vue'),
                Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
            }
        }, {
            path: '/service/survey/:status',
            meta: {
                requiresAuth: true,
                publisher: 'municipal',
                campaignType: 'questionnaire',
            },
            components: {
                default: () => import(/* webpackChunkName: "Survey" */ '../pages/Survey.vue'),
                Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
            }
        }, {
            path: '/service/e-voting/:status',
            meta: {
                requiresAuth: true,
                publisher: 'municipal',
                campaignType: 'vote',
            },
            components: {
                default: () => import(/* webpackChunkName: "Survey" */ '../pages/Survey.vue'),
                Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
            }
        }, {
            path: '/service/qa/:id',
            meta: {
                requiresAuth: true,
                publisher: 'municipal'
            },
            components: {
                default: () => import(/* webpackChunkName: "QA" */ '../pages/QA.vue'),
                // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
            }
        }, {
            path: '/service/qre/:id',
            meta: {
                requiresAuth: true,
                publisher: 'municipal'
            },
            components: {
                default: () => import(/* webpackChunkName: "QA" */ '../pages/Questionnaire.vue'),
                // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
            }
        }, {
            path: '/service/check-in/:id',
            meta: {
                requiresAuth: true,
                publisher: 'municipal'
            },
            components: {
                default: () => import(/* webpackChunkName: "CheckIn" */ '../pages/CheckIn.vue'),
                // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
            }
        }, {
            path: '/scanner',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "Scanner" */ '../pages/Scanner.vue'),
                Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue'),
                NotificationBar: () => import(/* webpackChunkName: "NotificationBar" */ '../components/Layout/NotificationBar.vue'),
            }
        }, {
            path: '/tickets',
            meta: {
                requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "Tickets" */ '../pages/Tickets.vue')
        }, {
            path: '/tickets/:ticketId',
            meta: {
                requiresAuth: true
            },
            component: () => import(/* webpackChunkName: "TicketDetail" */ '../pages/TicketDetail.vue')
        }, {
            path: '/profile',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "Profile" */ '../pages/Profile.vue'),
                Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue'),
                NotificationBar: () => import(/* webpackChunkName: "NotificationBar" */ '../components/Layout/NotificationBar.vue'),
            }
        }, {
            path: '/map',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "AppointStoreMap" */ '../pages/AppointStoreMap.vue'),
                Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue'),
                NotificationBar: () => import(/* webpackChunkName: "NotificationBar" */ '../components/Layout/NotificationBar.vue'),
            }
        }, {
            path: '/record',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "Record" */ '../pages/Record.vue'),
                Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue'),
                NotificationBar: () => import(/* webpackChunkName: "NotificationBar" */ '../components/Layout/NotificationBar.vue')
            }
        }, {
            path: '/payment-record',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "PaymentRecord" */ '../pages/PaymentRecord.vue'),
                Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue'),
                NotificationBar: () => import(/* webpackChunkName: "NotificationBar" */ '../components/Layout/NotificationBar.vue')
            }
        }, {
            path: '/point-list',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "PointList" */ '../pages/PointList.vue'),
                Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
            }
        }, {
            path: '/qa/:id',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "QA" */ '../pages/QA.vue'),
                // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
            }
        }, {
            path: '/appoint-store',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "AppointStore" */ '../pages/AppointStore.vue'),
                // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
            }
        },
        // {
        //     path: '/consumption',
        //     meta: {
        //         requiresAuth: true
        //     },
        //     components: {
        //         default: () => import(/* webpackChunkName: "Consumption" */ '../pages/Consumption.vue'),
        //         // default: () => import(/* webpackChunkName: "Consumption" */ '../pages/PointPayment.vue'),
        //         // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
        //     }
        // },
        // {
        //     path: '/consumption-result',
        //     meta: {
        //         requiresAuth: true
        //     },
        //     components: {
        //         default: () => import(/* webpackChunkName: "ConsumptionResult" */ '../pages/ConsumptionResult.vue'),
        //         // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
        //     }
        // },
        {
            path: '/profile/edit',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "ProfileEdit" */ '../pages/ProfileEdit.vue'),
                // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
            }
        }, {
            path: '/notification',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "Notification" */ '../pages/Notification.vue'),
                // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
            }
        }, {
            path: '/campaign',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "Campaign" */ '../pages/Campaign.vue'),
                Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
            }
        }, {
            path: '/campaign/:campaignId/detail',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "CampaignDetail" */ '../pages/CampaignDetail.vue'),
            }
        // }, {
        //     path: '/questions',
        //     meta: {
        //         requiresAuth: true
        //     },
        //     components: {
        //         default: () => import(/* webpackChunkName: "ActiveQuestions" */ '../pages/ActiveQuestions.vue'),
        //         Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
        //     }
        }, {
            path: '/transfer',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "Transfer" */ '../pages/Transfer.vue')
            }
        }, {
            path: '/transfer-result',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "TransferResult" */ '../pages/TransferResult.vue')
            }
        }, {
            path: '/articles',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "Articles" */ '../pages/Articles.vue')
            }
        }, {
            path: '/articles/:id',
            meta: {
                requiresAuth: false
            },
            components: {
                default: () => import(/* webpackChunkName: "Article" */ '../pages/Article.vue')
            }
        }, {
            path: '/campaign-records',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "CampaignRecords" */ '../pages/CampaignRecords.vue')
            }
        }, {
            path: '/stores',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "Stores" */ '../pages/Stores.vue'),
                Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
            }
        }, {
            path: '/stores/:id/stamp-cards',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "StoreStamps" */ '../pages/StoreStampCards.vue')
            }
        }, {
            path: '/stores/:id/info',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "StoreInfo" */ '../pages/StoreInfo.vue')
            }
        }, {
            path: '/stamp-cards',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "MyStampCards" */ '../pages/MyStampCards.vue')
            }
        }, {
            path: '/stamp-cards/:id',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "StampCard" */ '../pages/StampCard.vue')
            }
        },{
            path: '/carrier/edit',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "ProfileEdit" */ '../pages/CarrierEdit.vue'),
                // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
            }
        },
        {
            path: '/websites',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "Websites" */ '../pages/Websites.vue')
            }
        },
        {
            path: '/code-exchange',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "CodeExchange" */ '../pages/CodeExchange.vue')
            }
        },
        {
            path: '/bind-ddpay',
            meta: {
                requiresAuth: true
            },
            components: {
                default: () => import(/* webpackChunkName: "CodeExchange" */ '../pages/BindDdpay.vue')
            }
        }
        // {
        //     path: '/point-payment',
        //     meta: {
        //     requiresAuth: true,
        //     },
        //     components: {
        //     default: () =>
        //         import(/* webpackChunkName: "Payment" */ '../pages/PointPayment.vue'),
        //     // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
        //     }
        // },
        // {
        //     path: '/payment',
        //     meta: {
        //         requiresAuth: true
        //     },
        //     components: {
        //         default: () => import(/* webpackChunkName: "Consumption" */ '../pages/Payment.vue'),
                
        //         // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
        //     }
        // },
        // {
        //     path: '/payment-result',
        //     meta: {
        //         requiresAuth: true
        //     },
        //     components: {
        //         default: () => import(/* webpackChunkName: "PaymentResult" */ '../pages/PaymentResult.vue'),
        //         // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
        //     }
        // },{
        //     path: '/payment-error',
        //     meta: {},
        //     components: {
        //         default: () => import(/* webpackChunkName: "Error" */ '../pages/PaymentError.vue'),
        //         // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
        //     }
        // }
    ]
}
