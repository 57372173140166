import i18n from '@/i18n'

export function getPaymentImage(service, type) {
  let icon = null;
  let method = `${service}-${type}`;
  switch(method) {
    case 'tappay-apple':
      icon = require('@/assets/icon/pApple.svg');
      break;
    case 'tappay-google':
      icon = require('@/assets/icon/pGoogle.svg');
      break;
    case 'tappay-line':
    case 'linepay-linepay':
      icon = require('@/assets/icon/pLine.svg');
      break;
    case 'easywallet-easywallet':
      icon = require('@/assets/icon/pEasy.svg');
      break;
    case 'tappay-jko':
      icon = require('@/assets/icon/pJko.svg');
      break;
    case 'taiwanpay-twqrp':
      icon = require('@/assets/icon/pTaiwan.svg');
      break;
    case 'offline-offline': //現場支付
      //icon = require('@/assets/icon/p_taiwan.png');
      break;
    default:
      icon = null;
      break;
  }
  return icon;
}

export function parseErrorCode(code) {
  let errorCodes = ['OR001', 'OR002', 'OR003', 'OR004', 'OR005'];
  if (errorCodes.includes(code)) {
    return i18n.t(`errorCodes.${code}`);
  }
  return null;
}


export function axiosErrorHandler (error, raw) {
  console.log('axiosErrorHandler', error);
  let errmsg = '';
  if (error.message) {
    errmsg = error.message;
  } else if (error.data) {
    errmsg = error.data.name + ': ' + error.data.message;
  } else if (error.statusText) {
    errmsg = error.statusText;
  } else {
    errmsg = error;
  }
  if (raw) {
    // 於前端需json.parse(e.message)取得原始error json
    throw new Error(JSON.stringify(error));
  } else {
    throw new Error(errmsg);
  }
}
