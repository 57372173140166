import { axiosErrorHandler } from '@/utils/utils';
import axios from 'axios';
let HOST = process.env.VUE_APP_API_HOST;
const merchantId = process.env.VUE_APP_MERCHANT_ID;

const state = () => ({
  linkedAccount: {
    partnerMemberId: null
  },
  settings: {},
  isProgressing: false
});

const mutations = {
  SET_LINKED_ACCOUNT (state, data) {
    state.linkedAccount = data;
  },
  SET_SETTINGS (state, data) {
    state.settings = data;
  },
  SET_IS_PROGRESSING (state, data) {
    state.isProgressing = data;
  }
};

const actions = {
  getLinkedAccount ({ commit }, data) {
    let _merchantId = data.merchantId || merchantId;
    var config = {
      method: 'get',
      url: `${HOST}/identities/v1/merchants/${_merchantId}/members/${data.lineUserId}/pms/${data.pmCode}/linked-account`,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return axios.request(config)
      .then((response) => {
        if (response.data) { 
          commit('SET_LINKED_ACCOUNT', response.data); 
        } else { 
          commit('SET_LINKED_ACCOUNT', {partnerMemberId: null}); 
        }
        return response;
      }).catch((error) => {
        axiosErrorHandler(error);
      });
  },
  getLinkedUri ({ commit }, data) {
    commit('SET_IS_PROGRESSING', true);
    let _merchantId = data.merchantId || merchantId;
    var config = {
      method: 'post',
      url: `${HOST}/identities/v1/merchants/${_merchantId}/members/${data.lineUserId}/account-link/link-uri`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };
    return axios.request(config)
      .then((response) => {
        return response;
      }).catch((error) => {
        axiosErrorHandler(error);
      })
      .finally(() => {
        commit('SET_IS_PROGRESSING', false);
      });
  },
  unlink ({ commit }, data) {
    let _merchantId = data.merchantId || merchantId;
    var config = {
      method: 'post',
      url: `${HOST}/identities/v1/merchants/${_merchantId}/members/${data.lineUserId}/pms/${data.pmCode}/account-unlink`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };
    return axios.request(config)
      .then(() => {
        commit('SET_LINKED_ACCOUNT', { partnerMemberId: null });
      }).catch((error) => {
        axiosErrorHandler(error);
      });
  },
  getSettings ({ commit }, data) {
    let _merchantId = data.merchantId || merchantId;
    var config = {
      method: 'get',
      url: `${HOST}/identities/v1/merchants/${_merchantId}/members/${data.lineUserId}/pms/${data.pmCode}/account-link/settings`,
      headers: {
        'Content-Type': 'application/json',
      }
    };
    return axios.request(config)
      .then((response) => {
        commit('SET_SETTINGS', response.data);
        return response;
      }).catch((error) => {
        axiosErrorHandler(error);
      });
  }
};
const getters = {
  getLinkedAccount: state => state.linkedAccount,
  getSettings: state => state.settings
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}